import styled from 'styled-components'

import Box from '../Box'

const TextBody = styled(Box)``

TextBody.defaultProps = {
  fontFamily: 'sans',
  fontSize: [2, 3],
  fontWeight: 400, // regular
  lineHeight: 'body',
}

export default TextBody
